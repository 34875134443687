<template>
  <div>
    <modal className="w-11/12 sm:w-443px" ref="updateBranchModal">
      <h1 class="text-xl text-left font-bold mb-8">
        Update {{ branch.name }} Details
      </h1>
      <form @submit.prevent="updateBranch">
        <template v-if="getFormError(updateBranchForm)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon">!</span>
            <span>{{ getFormError(updateBranchForm) }}</span>
          </div>
        </template>

        <form-group
          name="name"
          :form="updateBranchForm"
          v-model="updateBranchForm.data.name.value"
        >
          Name
        </form-group>

        <div class="mt-3">
          <form-group
            type="select"
            :options="regionOptions"
            name="region_id"
            :form="updateBranchForm"
            v-model="updateBranchForm.data.region_id.value"
          >
            Region
          </form-group>
        </div>

        <div class="mt-3">
          <form-group
            name="address"
            :form="updateBranchForm"
            v-model="updateBranchForm.data.address.value"
          >
            Address
          </form-group>
        </div>

        <div class="mt-3">
          <form-group
            type="phone"
            name="phone"
            :form="updateBranchForm"
            v-model="updateBranchForm.data.phone.value"
          >
            Phone Number
          </form-group>
        </div>

        <form-group
          name="account_no"
          :form="updateBranchForm"
          v-model="updateBranchForm.data.account_no.value"
        >
          Account No
        </form-group>

        <div class="mt-3">
          <form-group
            type="select"
            :options="bankOptions"
            name="bank_name"
            :form="updateBranchForm"
            v-model="updateBranchForm.data.bank_name.value"
          >
            Bank Name
          </form-group>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
          <form-group
            type="select"
            :options="states"
            name="state"
            :form="updateBranchForm"
            v-model="updateBranchForm.data.state.value"
          >
            State
          </form-group>

          <form-group
            type="select"
            :options="lgas"
            name="lga"
            :form="updateBranchForm"
            v-model="updateBranchForm.data.lga.value"
          >
            LGA
          </form-group>
        </div>

        <div class="mt-3 mb-5 flex flex-row">
          <label class="custom-switch">
            <input
              type="checkbox"
              class="custom-switch-input"
              v-model="updateBranchForm.data.show_account.value"
            />
            <span class="custom-switch-label"></span>
          </label>
          <span class="text-sm font-semibold mt-1 ml-1">
            {{ isAccountEnabled ? 'Hide' : 'Show' }} Account Details
          </span>
        </div>

        <!-- <div class="mt-3 mb-5 flex items-center">
          <input
            type="checkbox"
            :id="updateBranchForm.data.show_account.value"
            v-model="updateBranchForm.data.show_account.value"
          />
          <label
            :for="updateBranchForm.data.show_account.value"
            class="text-sm ml-1"
          >
            Show Account Details
          </label>
        </div> -->

        <div class="flex justify-center">
          <button
            class="btn btn-blue w-full font-normal"
            type="submit"
            :disabled="updateBranchForm.loading"
          >
            Submit
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    branch: {
      type: Object,
      default: () => {}
    },
    regions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      updateBranchForm: this.$options.basicForm([
        { name: 'region_id', rules: 'required|aplpha' },
        { name: 'name', rules: 'required' },
        { name: 'address', rules: 'required' },
        { name: 'state', rules: 'nullable' },
        { name: 'lga', rules: 'nullable' },
        { name: 'phone', rules: 'required|phone' },
        { name: 'account_no', rules: 'required|number' },
        { name: 'bank_name', rules: 'required|aplpha' },
        { name: 'show_account', rules: 'nullable' }
      ])
    };
  },
  computed: {
    ...mapState(['states', 'banks']),
    regionOptions() {
      return this.regions.length
        ? this.regions?.map(region => ({
            title: region.name,
            value: region.id
          }))
        : [];
    },
    bankOptions() {
      return this.banks.length
        ? this.banks.map(bank => ({
            title: bank.name,
            value: bank.code
          }))
        : [];
    },
    states() {
      return this.$store.state.states?.map(state => state.name);
    },
    lgas() {
      return (
        this.$store.state.states
          ?.find(state => state.name === this.updateBranchForm.data.state.value)
          ?.locals.map(lga => lga.name) || []
      );
    },
    isAccountEnabled() {
      return this.updateBranchForm.data.show_account.value;
    }
  },
  mounted() {
    this.mapBranchData();
    this.$refs.updateBranchModal.open();
  },
  methods: {
    mapBranchData() {
      for (let key in this.updateBranchForm.data) {
        this.updateBranchForm.data[key].value = this.branch[key] || '';
      }
    },
    async updateBranch() {
      this.updateBranchForm.loading = true;
      await this.sendRequest('admin.merchants.branch.update', this.branch?.id, {
        data: this.getFormData(this.updateBranchForm),
        success: () => this.$parent.$emit('success'),
        error: error => (this.updateBranchForm.error = error)
      }).finally(() => (this.updateBranchForm.loading = false));
    }
  }
};
</script>
